import { TextResult } from "dynamsoft-javascript-barcode";
import { PDF_MIME_TYPES } from 'src/constants/mimes';
import { DriverLicenseImageType } from 'src/constants/person';
import DBR from 'src/lib/dbr'

import { getToastDuration } from '.';
import { processFileForUpload } from "./process-file-for-upload";
import { toast } from './toast';
import { getAssetPath } from "./url/get-asset-path";


export const convertPdfFileToImages = async (file: File, pdftronKey): Promise<File[]> => {
    const viewerDiv = document.createElement('div');
    viewerDiv.style.display = 'none';
    viewerDiv.style.position = 'absolute';
    viewerDiv.style.visibility = 'hidden';
    document.body.appendChild(viewerDiv);
    try {
        const WebViewer = (await import('@pdftron/webviewer')).default;
        const instance = await WebViewer(
            {
                disableObjectURLBlobs: true,
                licenseKey: pdftronKey,
                isReadOnly: true,
                fullAPI: true,
                disableLogs: true,
                path: getAssetPath('/static/webviewer10'),
                enableAnnotations: false,
            },
            viewerDiv,
        );

        const { PDFNet } = instance.Core;
        await PDFNet.initialize();
        // read file as dataurl
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = async () => {
            const filesList: File[] = [];
            const dataUrl = fileReader.result as string;
            const pdfDoc = await PDFNet.PDFDoc.createFromURL(dataUrl);
            pdfDoc.initSecurityHandler();
            pdfDoc.lock();
            const pdfdraw = await PDFNet.PDFDraw.create(92);
            const itr = await pdfDoc.getPageIterator();
            while (await itr.hasNext()) { //  Read every page
                const currPage = await itr.current();
                // @ts-ignore
                const pngBuffer = await pdfdraw.exportStream(currPage, 'PNG');
                // buffer to file
                const blob = new Blob([pngBuffer], { type: 'image/png' });
                const fileName = `${file.name.split('.')[0]}.png`;
                const fileObject = new File([blob], fileName, { type: 'image/png' });
                filesList.push(fileObject);
                await itr.next()
            }
            await instance.UI.closeDocument();
            document.body.removeChild(viewerDiv);
            return filesList;
        }
    } catch (error) {
        document.body.removeChild(viewerDiv);
        return []
    }
}

export const fileToDriverLicenseImage = async (file: File, type: keyof typeof DriverLicenseImageType, pdftronKey): Promise<File> => {

    if (PDF_MIME_TYPES.includes(file.type as any)) {
        try {
            const images = await convertPdfFileToImages(file, pdftronKey);
            if (type === DriverLicenseImageType.front) {
                return images?.[0];
            } else if (type === DriverLicenseImageType.back) {
                const promises = images.map(async (image) => imageHasBarcode(image));
                const imagesWithBarcode = await Promise.allSettled(promises);
                const imageWithBarcodeIndex = imagesWithBarcode.findIndex((result) => result.status === 'fulfilled' && result.value === true);
                return images?.[imageWithBarcodeIndex > -1 ? imageWithBarcodeIndex : 0];
            }
        } catch (error) {
            const message = 'Error converting PDF to image';
            toast({ type: 'error', content: message, duration: getToastDuration(message) });
            toast({
                type: 'error',
                content: message,
                duration: getToastDuration(message),
            });
        }
    }

    return await processFileForUpload(file, pdftronKey);
}

const getCodeReaderInstance = async () => {
    let cr = await DBR.BarcodeReader.createInstance();
    let settings = await cr.getRuntimeSettings();
    settings.barcodeFormatIds = DBR.EnumBarcodeFormat.BF_PDF417;
    await cr.updateRuntimeSettings(settings);
    return cr;
}

const readDriversLicenseBarcode = async (file: File): Promise<TextResult[]> => {
    const codeReader = await getCodeReaderInstance();

    if (!codeReader) {
        return [];
    }

    return codeReader.decode(file);
}

const imageHasBarcode = async (file: File) => {
    const values = await readDriversLicenseBarcode(file);
    return values?.length > 0;
}