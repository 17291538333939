import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from 'components/dist/atoms/Button';
import { isPast, parseISO, sub } from 'date-fns';
import { Formik } from 'formik';
import { FC, useEffect } from 'react';
import { api } from 'src/api';
import { IndividualRequestDto } from 'src/backend';
import { MaskPhoneInput } from 'src/components/common/form/mask-phone-input';
import { MaskZipCodeInput } from 'src/components/common/form/mask-zip-code-input';
import { SSNMaskInput } from 'src/components/common/form/ssn-mask-input';
import { useUser } from 'src/hooks/use-user';
import { getUSStates, listsStatesSelector } from 'src/slices/lists';
import { createPersonErrorsSelector, createPersonStatusSelector, uploadDriverLicense } from 'src/slices/person';
import { useDispatch, useSelector } from 'src/store';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { formatDate } from 'src/utils/date/format-date';
import { isValidDate } from 'src/utils/date/is-valid-date';
import { toast } from 'src/utils/toast';

import { schema } from './upload-driver-license-form.validation';

interface UploadDriversLicenseFormProps {
    defaultValues: IndividualRequestDto;
    onSubmitted?: () => void;
}

const UploadDriversLicenseForm: FC<UploadDriversLicenseFormProps> = (props) => {
    const dispatch = useDispatch();
    const { user } = useUser();
    const USStates = useSelector(listsStatesSelector);
    const personStatus = useSelector(createPersonStatusSelector);
    const createPersonErrors: { [x: string]: string } = useSelector(createPersonErrorsSelector);

    useEffect(() => {
        dispatch(getUSStates());
    }, [dispatch])

    // if we don't have a user, don't render
    if (!user) {
        return null;
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                legalGivenName: user.givenName,
                legalFamilyName: user.familyName,
                familyName: user.familyName,
                givenName: user.givenName,
                number: '',
                mobilePhone: {
                    value: '',
                    locale: 'US'
                },
                ssn: "",
                expiration: '',
                dob: '',
                residentialAddress: {
                    streetAddressLine1: '',
                    streetAddressLine2: '',
                    postOfficeBox: '',
                    citySuburb: '',
                    stateProvinceRegion: '',
                    postalCode: '',
                    country: 'US',
                    currentAddress: true,
                },
                mailingAddress: {
                    streetAddressLine1: '',
                    streetAddressLine2: '',
                    postOfficeBox: '',
                    citySuburb: '',
                    stateProvinceRegion: '',
                    postalCode: '',
                    country: 'US'
                },
                vehicleClass: '',
                eyesColor: '',
                height: '',
                weight: '',
                hairColor: '',
                restrictions: '',
                dd: '',
                ...props.defaultValues
            }}
            validationSchema={schema}
            onSubmit={async (values, {
                setSubmitting
            }): Promise<void> => {
                setSubmitting(true);
                const individual = await api.getIndividualFromUser(user.id);
                const residentialAddresses = [
                    ...individual.residentialAddresses
                        .filter(address => !address.address.currentAddress)
                        .map(address => ({
                            address: {
                                ...address.address,
                                currentAddress: false
                            }
                        })),
                    {
                        address: {
                            ...values.residentialAddress,
                            currentAddress: true
                        }
                    }
                ]
                await dispatch(uploadDriverLicense({
                    individualId: individual.id,
                    dto: ({
                        ...values,
                        legalFamilyName: values.familyName,
                        legalGivenName: values.givenName,
                        residentialAddresses
                    } as unknown) as IndividualRequestDto
                }));

                await api.postIndividualInformation(individual.id,
                    ({
                        ...values,
                        legalFamilyName: values.familyName,
                        legalGivenName: values.givenName,
                        residentialAddresses
                    }));
                setSubmitting(false);
                toast({
                    type: 'success',
                    content: 'Updates saved successfully'
                })
                props.onSubmitted?.();
            }}>
            {({
                errors,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                setErrors,
                isSubmitting,
                submitCount,
                touched,
                values
            }): JSX.Element => {
                const isExpired = values.expiration ? isPast(parseISO(values.expiration)) : false;
                const isExpiringIn90Days = !isExpired && values.expiration ? isPast(sub(parseISO(values.expiration), { days: 90 })) : false;
                const isFormErrored = Object.keys({ ...errors, ...createPersonErrors }).length > 0 && submitCount;

                const handleDateChange = async (name: string, date: Date | string): Promise<void> => {
                    try {
                        if (typeof date === 'string') {
                            throw new Error('Invalid date');
                        }
                        if (isValidDate(date)) {
                            await setFieldValue(name, formatDate(date));
                            setFieldTouched(name);
                            setErrors({ ...errors, [name]: undefined });
                        } else {
                            // throw error to be caught in catch block
                            throw new Error('Invalid date');
                        }
                    } catch (error) {
                        console.error(error);
                        // set field value with invalid date
                        setFieldValue(name, error.message, true);
                        setFieldTouched(name);
                    }
                };

                return (
                    <form onSubmit={handleSubmit}>
                        <MuiCard sx={{ boxShadow: 0, border: `solid 1px ${themeV2.neutral.grey[5]}` }}>
                            {isExpiringIn90Days && <Alert severity="warning" sx={{ borderRadius: 0 }}>
                                Your drivers license expires in 90 days. Update now or do this later
                            </Alert>}
                            {isExpired && <Alert severity="error"
                                sx={{
                                    borderRadius: 0,
                                    backgroundColor: themeV2.colors.red[5],
                                    color: themeV2.colors.red[100],
                                    '& .MuiAlert-icon': {
                                        color: themeV2.colors.red[100]
                                    }
                                }}>
                                Your drivers license has expired. Update now or do this later
                            </Alert>}
                            <CardContent sx={{
                                py: 4,
                                px: 3,
                                overflow: 'visible',

                            }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.givenName && errors.givenName)}
                                            fullWidth
                                            helperText={touched.givenName && errors.givenName}
                                            label="Given Name"
                                            name="givenName"
                                            onChange={handleChange}
                                            value={values.givenName ?? ""}
                                            variant="outlined"
                                            disabled={!!props.defaultValues?.givenName}
                                            InputLabelProps={{
                                                shrink: !!values.givenName
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.familyName && errors.familyName)}
                                            fullWidth
                                            helperText={touched.familyName && errors.familyName}
                                            label="Family Name"
                                            name="familyName"
                                            onChange={handleChange}
                                            value={values.familyName ?? ""}
                                            variant="outlined"
                                            disabled={!!props.defaultValues?.familyName}
                                            InputLabelProps={{
                                                shrink: !!values.familyName
                                            }}
                                        />
                                    </Grid>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid item xs={12} lg={6}>
                                            <DatePicker
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                label="Issue Date"
                                                onAccept={() => setFieldTouched('issueDate')}
                                                onChange={(date) => handleDateChange('issueDate', date)}
                                                onClose={() => setFieldTouched('issueDate')}
                                                OpenPickerButtonProps={{
                                                    'aria-label': 'choose issue date',
                                                }}
                                                inputFormat="MM-dd-yyyy"
                                                mask="__-__-____"
                                                renderInput={(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        fullWidth
                                                        name="issueDate"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.issueDate && errors.issueDate)}
                                                        helperText={touched.issueDate && errors.issueDate}
                                                    />
                                                )}
                                                disabled={!!props.defaultValues?.issueDate && !errors.issueDate}
                                                value={values?.issueDate ? parseISO(values?.issueDate) : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <DatePicker
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                label="Expiration Date"
                                                onAccept={() => setFieldTouched('expiration')}
                                                onChange={(date) => handleDateChange('expiration', date)}
                                                onClose={() => setFieldTouched('expiration')}
                                                OpenPickerButtonProps={{
                                                    'aria-label': 'choose expiration date',
                                                }}
                                                renderInput={(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        fullWidth
                                                        name="expiration"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.expiration && errors.expiration)}
                                                        helperText={touched.expiration && errors.expiration}

                                                    />
                                                )}
                                                disabled={!!props.defaultValues?.expiration && !errors.expiration}
                                                value={values?.expiration ? parseISO(values?.expiration) : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <DatePicker
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                label="Birth Date"
                                                onAccept={() => setFieldTouched('dob')}
                                                onChange={(date) => handleDateChange('dob', date)}
                                                onClose={() => setFieldTouched('dob')}
                                                OpenPickerButtonProps={{
                                                    'aria-label': 'choose birth date',
                                                }}
                                                renderInput={(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        fullWidth
                                                        name="dob"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.dob && errors.dob)}
                                                        helperText={touched.dob && errors.dob}

                                                    />
                                                )}
                                                disabled={!!props.defaultValues?.dob && !errors.dob}
                                                value={values?.dob ? parseISO(values?.dob) : ""}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.ssn && errors.ssn)}
                                            fullWidth
                                            helperText={touched.ssn && errors.ssn}
                                            label="Social Security Number"
                                            name="ssn"
                                            InputProps={{
                                                inputComponent: SSNMaskInput
                                            }}
                                            InputLabelProps={{
                                                shrink: !!values.ssn
                                            }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values?.ssn ?? ""}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.residentialAddress?.streetAddressLine1 && errors.residentialAddress?.streetAddressLine1)}
                                            fullWidth
                                            helperText={touched.residentialAddress?.streetAddressLine1 && errors.residentialAddress?.streetAddressLine1}
                                            label="Address 1"
                                            name="residentialAddress.streetAddressLine1"
                                            onChange={handleChange}
                                            value={values.residentialAddress?.streetAddressLine1 ?? ""}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.residentialAddress?.streetAddressLine2 && errors.residentialAddress?.streetAddressLine2)}
                                            fullWidth
                                            helperText={touched.residentialAddress?.streetAddressLine2 && errors.residentialAddress?.streetAddressLine2}
                                            label="Address 2"
                                            name="residentialAddress.streetAddressLine2"
                                            onChange={handleChange}
                                            value={values.residentialAddress?.streetAddressLine2 ?? ""}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.residentialAddress?.citySuburb && errors.residentialAddress?.citySuburb)}
                                            fullWidth
                                            helperText={touched.residentialAddress?.citySuburb && errors.residentialAddress?.citySuburb}
                                            label="City"
                                            name="residentialAddress.citySuburb"
                                            onChange={handleChange}
                                            value={values.residentialAddress?.citySuburb ?? ""}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        {USStates.length > 0 && <TextField
                                            error={Boolean(touched.residentialAddress?.stateProvinceRegion && errors.residentialAddress?.stateProvinceRegion)}
                                            fullWidth
                                            helperText={touched.residentialAddress?.stateProvinceRegion && errors.residentialAddress?.stateProvinceRegion}
                                            label="State"
                                            select
                                            SelectProps={{ native: true }}
                                            name="residentialAddress.stateProvinceRegion"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={handleChange}
                                            value={values.residentialAddress?.stateProvinceRegion ?? ""}
                                            variant="outlined">
                                            <option value="" disabled>Select state</option>
                                            {USStates.map((usState) => (
                                                <option
                                                    key={usState.value}
                                                    value={usState.value}
                                                >
                                                    {usState.label}
                                                </option>
                                            ))}
                                        </TextField>}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.mobilePhone?.value && errors.mobilePhone?.value)}
                                            fullWidth
                                            helperText={touched.mobilePhone?.value && errors.mobilePhone?.value}
                                            label="Phone number"
                                            InputProps={{
                                                inputComponent: MaskPhoneInput
                                            }}
                                            name="mobilePhone.value"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: !!values.mobilePhone?.value
                                            }}
                                            value={values.mobilePhone?.value ?? ""}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            error={Boolean(touched.residentialAddress?.postalCode && (errors.residentialAddress?.postalCode))}
                                            fullWidth
                                            helperText={touched.residentialAddress?.postalCode && (errors.residentialAddress?.postalCode)}
                                            label="Zip"
                                            InputProps={{
                                                inputComponent: MaskZipCodeInput,
                                            }}
                                            InputLabelProps={{
                                                shrink: !!values.residentialAddress?.postalCode
                                            }}
                                            name="residentialAddress.postalCode"
                                            onChange={handleChange}
                                            value={values.residentialAddress?.postalCode ?? ""}
                                            variant="outlined"
                                        />
                                    </Grid>

                                </Grid>

                                {isFormErrored > 0 && <Alert severity='error' sx={{ mt: 3 }}>
                                    There are items that require your attention.
                                </Alert>}
                                <Box
                                    sx={{
                                        mt: 3,
                                    }}
                                >
                                    <Button
                                        onClick={() => handleSubmit()}
                                        loading={isSubmitting || personStatus === 'loading'}
                                        type="submit"
                                        data-testid='submit-btn'
                                    >
                                        {personStatus === 'loading' && 'Loading...'}
                                        {personStatus !== 'loading' && 'Submit'}
                                    </Button>
                                </Box>
                            </CardContent>
                        </MuiCard>
                    </form>
                )
            }}
        </Formik>
    );
};

export default UploadDriversLicenseForm;
