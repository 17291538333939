import { type BarcodeReader } from 'dynamsoft-javascript-barcode';
import { useCallback, useEffect, useState } from 'react'
import { IndividualRequestDto, UnitedStates } from 'src/backend';
import DBR from 'src/lib/dbr'
import { driversLicenseInfo } from "src/mocks/drivers-license-info";
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';
import { convertDob, parseDriverLicense } from 'src/utils/s3';

type DriverLicenseField = {
    content: string
}

export const useCodeReader = () => {
    const [codeReader, setCodeReader] = useState<BarcodeReader>(null);
    const [decodeOnProgress, setDecodeOnProgress] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                let cr = await DBR.BarcodeReader.createInstance();
                let settings = await cr.getRuntimeSettings();
                settings.barcodeFormatIds = DBR.EnumBarcodeFormat.BF_PDF417;
                await cr.updateRuntimeSettings(settings);
                setCodeReader(cr);
            } catch (error) {
                notifyBugTracker(error);
            }
        })()
    }, [])

    const checkForMockDriversLicenseData = useCallback(async (file: File | string): Promise<{ barcodeText: any }[]> => {
        const foundMock = driversLicenseInfo.mock.find(({ filename }) => {
            return (file instanceof File ? file.name : file).trim().toLowerCase() === filename.trim().toLocaleLowerCase();
        })
        // if we find a mock, return the mock data
        if (foundMock) {
            return [{ barcodeText: foundMock.barcodeText }]
        }
        // if the barcode reader is ready, use it
        if (codeReader) {
            return codeReader.decode(file);
        }
        // otherwise, return an empty array
        return [];
    }, [codeReader])

    const decode = useCallback(async (file: File | string): Promise<IndividualRequestDto> => {
        const values = await checkForMockDriversLicenseData(file);
        setDecodeOnProgress(true);
        setDecodeOnProgress(false);
        if (values.length > 0) {
            const [firstRecord] = values;
            const details = parseDriverLicense(firstRecord.barcodeText);
            const driversLicense: IndividualRequestDto = {
                dob: convertDob(((details['DBB'] as unknown) as DriverLicenseField)?.content) ?? '',
                fullName: ((details['DAA'] as unknown) as DriverLicenseField)?.content ?? '',
                emailAddress: ((details['DCI'] as unknown) as DriverLicenseField)?.content ?? '',
                state: (((details['DAJ'] as unknown) as DriverLicenseField)?.content as UnitedStates) ?? null,
                legalGivenName: ((details['DAC'] as unknown) as DriverLicenseField)?.content ?? '',
                givenName: ((details['DAC'] as unknown) as DriverLicenseField)?.content ?? '',
                vehicleClass: ((details['DCA'] as unknown) as DriverLicenseField)?.content ?? '',
                height: ((details['DAU'] as unknown) as DriverLicenseField)?.content ?? '',
                weight: ((details['DAW'] as unknown) as DriverLicenseField)?.content ?? '',
                eyesColor: ((details['DAY'] as unknown) as DriverLicenseField)?.content ?? '',
                hairColor: ((details['DAZ'] as unknown) as DriverLicenseField)?.content ?? '',
                dd: ((details['DCF'] as unknown) as DriverLicenseField)?.content ?? '',
                restrictions: ((details['DCB'] as unknown) as DriverLicenseField)?.content ?? '',
                legalFamilyName: ((details['DCS'] as unknown) as DriverLicenseField)?.content ?? '',
                familyName: ((details['DCS'] as unknown) as DriverLicenseField)?.content ?? '',
                number: ((details['DAQ'] as unknown) as DriverLicenseField)?.content ?? '',
                expiration: convertDob(((details['DBA'] as unknown) as DriverLicenseField)?.content) ?? '',
                complianceType: ((details['DDA'] as unknown) as DriverLicenseField)?.content ?? '',
                countryIdentification: ((details['DCG'] as unknown) as DriverLicenseField)?.content ?? '',
                legalMiddleName: ((details['DAD'] as unknown) as DriverLicenseField)?.content ?? '',
                issueDate: convertDob(((details['DBD'] as unknown) as DriverLicenseField)?.content) ?? '',
                inventoryControlNumber: ((details['DCK'] as unknown) as DriverLicenseField)?.content ?? '',
                endorsementCodes: ((details['DCD'] as unknown) as DriverLicenseField)?.content ?? '',
                organDonorIndicator: ((details['DDK'] as unknown) as DriverLicenseField)?.content ?? '',
                sex: ((details['DBC'] as unknown) as DriverLicenseField)?.content ?? '',
                residentialAddress: {
                    "streetAddressLine1": ((details['DAG'] as unknown) as DriverLicenseField)?.content ?? '',
                    "streetAddressLine2": ((details['DAH'] as unknown) as DriverLicenseField)?.content ?? '',
                    "citySuburb": ((details['DAI'] as unknown) as DriverLicenseField)?.content ?? '',
                    "stateProvinceRegion": ((details['DAJ'] as unknown) as DriverLicenseField)?.content ?? '',
                    "postalCode": ((details['DAK'] as unknown) as DriverLicenseField)?.content ?? '',
                    "postOfficeBox": null,
                    "country": "US",
                    "currentAddress": false,
                    "moveInDate": "",
                    "moveOutDate": ""

                },
                maritalStatus: '',
                mobilePhone: {
                    locale: "US",
                    value: "",
                },
                mailingAddress: {
                    "streetAddressLine1": ((details['DAL'] as unknown) as DriverLicenseField)?.content ?? '',
                    "streetAddressLine2": ((details['DAM'] as unknown) as DriverLicenseField)?.content ?? '',
                    "citySuburb": ((details['DAN'] as unknown) as DriverLicenseField)?.content ?? '',
                    "stateProvinceRegion": ((details['DAO'] as unknown) as DriverLicenseField)?.content ?? '',
                    "postalCode": ((details['DAP'] as unknown) as DriverLicenseField)?.content ?? '',
                    "postOfficeBox": null,
                    "country": "US",
                    currentAddress: true,
                    moveInDate: '',
                    moveOutDate: ''
                },
                ssn: '',
                userIndividual: null,
                companyAddress: null,
                companyName: null,
                companyPhone: null,
                companyWebsite: null,
                citizenshipCountry: '',
                employments: [],
                passportNumber: '',
                permanentResidenceCardNumber: '',
                permanentResidentAlien: false,
                placeOfBirth: '',
                residentialAddresses: [],
                usCitizen: false,
            };
            return driversLicense;
        }
        return null;
    }, [checkForMockDriversLicenseData])

    return { codeReader, decode, decodeOnProgress }
}