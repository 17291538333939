import * as Yup from 'yup';

export const schema = Yup
    .object()
    .shape({
        number: Yup.string().nullable(),
        expiration: Yup.date()
            .typeError('Invalid date')
            // min date is 1900
            .min(new Date(1900, 0, 1), 'Invalid date')
            .nullable(),
        issueDate: Yup.date()
            .typeError('Invalid date')
            .min(new Date(1900, 0, 1), 'Invalid date')
            .nullable(),
        dob: Yup.date()
            .typeError('Invalid date')
            .min(new Date(1900, 0, 1), 'Invalid date')
            // must be before today
            .max(new Date(new Date().setDate(new Date().getDate() - 1)), 'Must be before today')
            .required("Required")
            .nullable(),
        // socialSecurityNumber: Yup.string().required("Required").nullable(),
        residentialAddress: Yup.object().shape({
            streetAddressLine1: Yup.string().required("Required").nullable(),
            // 'address.streetAddressLine2': Yup.string().nullable(),
            citySuburb: Yup.string().required("Required").nullable(),
            stateProvinceRegion: Yup.string().required("Required").nullable(),
            postalCode: Yup.string().required("Required").nullable(),
            // postOfficeBox': Yup.string().nullable(),
            country: Yup.string().required("Required").nullable()
        }),
        ssn: Yup
            .string()
            .matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/, 'Social Security Number must be up to 9 digits without dashes')
            .required('Social Security Number is Required')
            .nullable(),
    })