export const driversLicenseInfo = {
    mock: [
        {
            "filename": "bankhead_rickey_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ5214367\nDCSBANKHEAD\nDDEN\nDACRICKEY\nDDFN\nDADD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD10012019\nDBB10081968\nDBA10082024\nDBC1\nDAU071 in\nDAYBLU\nDAG402 CANYON OAKS WY\nDAISALT LAKE CITY\nDAJUT\nDAK841032281  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "connolly_john_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ75845621\nDCSCONNOLLY\nDDEN\nDACJOHN\nDDFN\nDADA\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD09162018\nDBB09161986\nDBA09162027\nDBC1\nDAU071 in\nDAYBLU\nDAG189 ASHCREEK DR\nDAITOWUERVILLE\nDAJUT\nDAK847745074  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "debari_angelantoni_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ817917717\nDCSDEBARI\nDDEN\nDACANGELANTONI\nDDFN\nDAD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD05202019\nDBB12021964\nDBA04232025\nDBC1\nDAU071 in\nDAYBLU\nDAG54 PO BOX 54\nDAICLEARFIELD\nDAJUT\nDAK840890054  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "lawless_john_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ123654456\nDCSLAWLESS\nDDEN\nDACJOHN\nDDFN\nDADA\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD12082018\nDBB12081978\nDBA12082023\nDBC1\nDAU071 in\nDAYBLU\nDAG14260 CARROLL BRIDGE RD\nDAISMITHFIELD\nDAJVA\nDAK234307201  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "lyon_richard_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ3541236\nDCSLYON\nDDEN\nDACRICHARD\nDDFN\nDADB\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD03292019\nDBB01051961\nDBA04282025\nDBC1\nDAU071 in\nDAYBLU\nDAG10496 Silver Mountain Dr Apt. D\nDAISandy\nDAJUT\nDAK840944453  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "nations_steve_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ7568458951\nDCSNATIONS\nDDEN\nDACSTEVE\nDDFN\nDADG\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11292019\nDBB04301974\nDBA12282025\nDBC1\nDAU071 in\nDAYBLU\nDAG2284 W 5950 S\nDAIRoy\nDAJUT\nDAK840671439  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "raymond_kyle_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ3332211\nDCSRAYMOND\nDDEN\nDACKYLE\nDDFN\nDADJ\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD12012020\nDBB12011985\nDBA12012026\nDBC1\nDAU071 in\nDAYBLU\nDAG2581 BRIDLE OAK DR\nDAISOUTH JORDAN\nDAJUT\nDAK840953483  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "terwey_denise_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ666444222\nDCSTERWEY\nDDEN\nDACDENISE\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11292020\nDBB11171990\nDBA12282026\nDBC1\nDAU071 in\nDAYBLU\nDAG337 BAKER LN\nDAIGRANTSVILLE\nDAJUT\nDAK840290000  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "uphold_robert_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ666555444\nDCSUPHOLD\nDDEN\nDACROBERT\nDDFN\nDADE\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD09292017\nDBB10051954\nDBA10052022\nDBC1\nDAU071 in\nDAYBLU\nDAG27366 PERRY ST\nDAIROSEVILLE\nDAJMI\nDAK480662743  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "wolford_tina_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ817971717\nDCSWOLFORD\nDDEN\nDACTINA\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD02292020\nDBB12011989\nDBA03282026\nDBC1\nDAU071 in\nDAYBLU\nDAG151 S 100 W\nDAIWASHINGTON\nDAJUT\nDAK847801912  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "abreu_natalie_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQTL545796\nDCSABREU\nDDEN\nDACNATALIE\nDDFN\nDAD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11122020\nDBB11121978\nDBA11122025\nDBC1\nDAU071 in\nDAYBLU\nDAG806 VERNON RD\nDAICOLUMBUS\nDAJOH\nDAK432092465  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "worner_wayne_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQC7364290\nDCSWORNER\nDDEN\nDACWAYNE\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD07272019\nDBB07271980\nDBA07272024\nDBC1\nDAU071 in\nDAYBLU\nDAG6906 LENWOOD WY\nDAISAN JOSE\nDAJCA\nDAK951203132  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        
        {
            "filename": "cabanas_felipe_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ91645679\nDCSCABANAS\nDDEN\nDACFELIPE\nDDFN\nDAD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD10102020\nDBB10101979\nDBA10102030\nDBC1\nDAU071 in\nDAYBLU\nDAG 3451  COLONY  CV \nDAILEHI\nDAJUT\nDAK84043-4570\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "noack_hazel_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ6549851658\nDCSNOACK\nDDEN\nDACHAZEL\nDDFN\nDADL\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11112020\nDBB11111980\nDBA11112030\nDBC1\nDAU071 in\nDAYBLU\nDAG 1100  CHATHAM  DR \nDAIBELLEVILLE\nDAJIL\nDAK62221-7841\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "smith_robert_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ2315681568\nDCSSMITH\nDDEN\nDACROBERT\nDDFN\nDADA\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD12122020\nDBB12121981\nDBA12122030\nDBC1\nDAU071 in\nDAYBLU\nDAG 144  PO BOX 144   \nDAISOUTHWORTH\nDAJWA\nDAK98386-0144\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "lucion_william_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ654353156\nDCSLUCION\nDDEN\nDACWILLIAM\nDDFN\nDADC\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD01012020\nDBB01011982\nDBA01012030\nDBC1\nDAU071 in\nDAYBLU\nDAG 792  PO BOX 792   \nDAIHANFORD\nDAJCA\nDAK93232-0792\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "hurley_craig_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ889531865\nDCSHURLEY\nDDEN\nDACCRAIG\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD02022020\nDBB02021983\nDBA02022030\nDBC1\nDAU071 in\nDAYBLU\nDAG 3103  1ST  ST \nDAIIDAHO FALLS\nDAJID\nDAK83401-4522\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "rathbun_timothy_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ235641356\nDCSRATHBUN\nDDEN\nDACTIMOTHY\nDDFN\nDADJ\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD03032020\nDBB03031984\nDBA03032030\nDBC1\nDAU071 in\nDAYBLU\nDAG 1017  AUTUMN  DR \nDAIWEST BEND\nDAJWI\nDAK53090-5432\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "carter_joseph_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ325646561\nDCSCARTER\nDDEN\nDACJOSEPH\nDDFN\nDADE\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD04042020\nDBB04041985\nDBA04042030\nDBC1\nDAU071 in\nDAYBLU\nDAG 9409 N 105TH EAST  AV \nDAIOWASSO\nDAJOK\nDAK74055-7248\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "harris_myrtis_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ584565\nDCSHARRIS\nDDEN\nDACMYRTIS\nDDFN\nDADE\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD05052020\nDBB05051986\nDBA05052030\nDBC1\nDAU071 in\nDAYBLU\nDAG 4400 S QUEBEC  ST X106\nDAIDENVER\nDAJCO\nDAK80237-2618\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "miller_timothy_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ32168856\nDCSMILLER\nDDEN\nDACTIMOTHY\nDDFN\nDADL\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD06062020\nDBB06061987\nDBA06062030\nDBC1\nDAU071 in\nDAYBLU\nDAG 7710  GIBRALTER  TE \nDAIAPPLE VALLEY\nDAJMN\nDAK55124-6124\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "solomon_kelvin_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ9851115\nDCSSOLOMON\nDDEN\nDACKELVIN\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD07072020\nDBB07071988\nDBA07072030\nDBC1\nDAU071 in\nDAYBLU\nDAG 675  PO BOX 675   \nDAITATE\nDAJGA\nDAK30177-0675\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "laney_susan_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ25318486\nDCSLANEY\nDDEN\nDACSUSAN\nDDFN\nDADD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD08082020\nDBB08081989\nDBA08082030\nDBC1\nDAU071 in\nDAYBLU\nDAG 1814  OAK HILL  LN LN\nDAIRICHMOND\nDAJVA\nDAK23223-1920\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "dupree_mark_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ78653473\nDCSDUPREE\nDDEN\nDACMARK\nDDFN\nDADS\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD09092020\nDBB09091990\nDBA09092030\nDBC1\nDAU071 in\nDAYBLU\nDAG 1514  BIRCHWOOD  AV \nDAIKISSIMMEE\nDAJFL\nDAK34744-4015\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "gardner_gary_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ9885325\nDCSGARDNER\nDDEN\nDACGARY\nDDFN\nDADCLARK\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD10012020\nDBB10011991\nDBA10012030\nDBC1\nDAU071 in\nDAYBLU\nDAG 7301  CORONA DEL SOL   \nDAIEL PASO\nDAJTX\nDAK79911-3002\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "bell_niketria_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ5169324\nDCSBELL\nDDEN\nDACNIKETRIA\nDDFN\nDADP\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11022020\nDBB11021992\nDBA11022030\nDBC1\nDAU071 in\nDAYBLU\nDAG 9272  61ST  ST \nDAIRIVERSIDE\nDAJCA\nDAK92509-4905\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "davis_darlie_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ156324\nDCSDAVIS\nDDEN\nDACDARLIE\nDDFN\nDADE\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD12032020\nDBB12031993\nDBA12032030\nDBC1\nDAU071 in\nDAYBLU\nDAG 13  CLIFF RIDGE  CT \nDAIHUDSON\nDAJWI\nDAK54016-7736\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "henry_sharon_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ684311\nDCSHENRY\nDDEN\nDACSHARON\nDDFN\nDADA\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD01042020\nDBB01041994\nDBA01042030\nDBC1\nDAU071 in\nDAYBLU\nDAG 7936  OLD 31 N  \nDAICHARLEVOIX\nDAJMI\nDAK49720-9424\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "jones_garlin_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ66654651\nDCSJONES\nDDEN\nDACGARLIN\nDDFN\nDADH\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD02052020\nDBB02051995\nDBA02052030\nDBC1\nDAU071 in\nDAYBLU\nDAG 2710  IKE  ST 130\nDAICHESAPEAKE\nDAJVA\nDAK23324-2920\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "espino_antonio_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ654168513\nDCSESPINO\nDDEN\nDACANTONIO\nDDFN\nDAD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD03062020\nDBB03061996\nDBA03062030\nDBC1\nDAU071 in\nDAYBLU\nDAG 3667  WILHELM  RD \nDAIHELLERTOWN\nDAJPA\nDAK18055-1221\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "camp_kenton_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ6523681\nDCSCAMP\nDDEN\nDACKENTON\nDDFN\nDADB\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD04072020\nDBB04071997\nDBA04072030\nDBC1\nDAU071 in\nDAYBLU\nDAG 3528  BERESFORD  DR \nDAIMODESTO\nDAJCA\nDAK95357-1511\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "demuth_ross_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ85231565\nDCSDEMUTH\nDDEN\nDACROSS\nDDFN\nDADR\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD05082020\nDBB05081998\nDBA05082030\nDBC1\nDAU071 in\nDAYBLU\nDAG 159  HIDDEN OAKS  DR \nDAIJOHNSON CITY\nDAJTN\nDAK37601-5407\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "pirkle_rosemary_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ358471568\nDCSPIRKLE\nDDEN\nDACROSEMARY\nDDFN\nDADJ\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD06092020\nDBB06091999\nDBA06092030\nDBC1\nDAU071 in\nDAYBLU\nDAG 3335  HIGHRIDGE  ST \nDAILAS CRUCES\nDAJNM\nDAK88012-8508\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "cawthorn_rebecca_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ98518675\nDCSCAWTHORN\nDDEN\nDACREBECCA\nDDFN\nDADANN\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD07102020\nDBB07102000\nDBA07102030\nDBC1\nDAU071 in\nDAYBLU\nDAG 8037  FALCON VIEW  DR \nDAIANTELOPE\nDAJCA\nDAK95843-5365\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "hoffman_rachel_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ258345\nDCSHOFFMAN\nDDEN\nDACRACHEL\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD08112020\nDBB08112001\nDBA08112030\nDBC1\nDAU071 in\nDAYBLU\nDAG 6019  CEZANNE  CT \nDAIROANOKE\nDAJVA\nDAK24018-5317\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "ray_rose_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ963852\nDCSRAY\nDDEN\nDACROSE\nDDFN\nDADA\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD09122020\nDBB09122002\nDBA09122030\nDBC1\nDAU071 in\nDAYBLU\nDAG 936  SPIKES  RD \nDAIPEARSON\nDAJGA\nDAK31642-6520\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "liu_fang_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ25816844\nDCSLIU\nDDEN\nDACFANG\nDDFN\nDAD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD10132020\nDBB10132003\nDBA10132030\nDBC1\nDAU071 in\nDAYBLU\nDAG 3820  OAK PARK  DR \nDAIFLOWER MOUND\nDAJTX\nDAK75028-1356\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "scotto_gerald_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ2445433\nDCSSCOTTO\nDDEN\nDACGERALD\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11132020\nDBB11132004\nDBA11132030\nDBC1\nDAU071 in\nDAYBLU\nDAG 904  LAYTON ST 2 \nDAIFLORENCE\nDAJSC\nDAK29506-6445\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "novell_david_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ6784565\nDCSNOVELL\nDDEN\nDACDAVID\nDDFN\nDADI\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD12152020\nDBB12152005\nDBA12152030\nDBC1\nDAU071 in\nDAYBLU\nDAG 19639  RIOPELLE  ST \nDAIDETROIT\nDAJMI\nDAK48203-1373\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "luckenbill_kathy_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ9056785475\nDCSLUCKENBILL\nDDEN\nDACKATHY\nDDFN\nDADD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD01162020\nDBB01162006\nDBA01162030\nDBC1\nDAU071 in\nDAYBLU\nDAG 459 W MAIN AV  \nDAIMYERSTOWN\nDAJPA\nDAK17067-1027\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "mcdonald_janet_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ3456780\nDCSMCDONALD\nDDEN\nDACJANET\nDDFN\nDADF\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD02172020\nDBB02172007\nDBA02172030\nDBC1\nDAU071 in\nDAYBLU\nDAG 6  PEPPERMINT  RD \nDAICOMMACK\nDAJNY\nDAK11725-1021\nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
    ]
}
