import { forwardRef } from 'react';
import InputMask from 'react-input-mask';


export const SSNMaskInput = forwardRef((props, ref) => {
    return (
        <InputMask
            {...props}
            inputRef={ref}
            mask="999-99-9999"
            maskChar={null}
        />
    );
});

SSNMaskInput.displayName = 'SSNMaskInput';