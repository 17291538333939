import { forwardRef } from 'react';
import InputMask from 'react-input-mask';

export const MaskPhoneInput = forwardRef((props, ref) => {
    const { ...other } = props;
    return (
        <InputMask
            {...other}
            inputRef={ref}
            mask="999-999-9999"
            maskChar="_"
        />
    );
});

MaskPhoneInput.displayName = 'MaskPhoneInput';