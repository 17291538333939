import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import Zoom from 'react-medium-image-zoom'
import { FormElementCamCapture } from "src/components/form-elements/form-element-cam-capture";
import { usePickFormElementUploader } from "src/components/form-elements/use-pick-form-element-uploader";
import { useFetchUserPreferences } from 'src/hooks/use-fetch-user-prefrences';
import { EditPencilIcon } from "src/icons/edit-pencil-icon";
import { Upload } from "src/icons/upload";
import { themeV2 } from "src/theme/mysherpas-theme-option";
import { createUrlSlug } from "src/utils";

interface DriverLicenseDropZoneProps extends DropzoneOptions {
    title: string;
    uploading: boolean;
    hasError?: boolean;
    uploaded?: boolean;
    imageSrc: string;
}

export const DriverLicenseDropZone = (props: DriverLicenseDropZoneProps) => {
    const {
        accept,
        disabled,
        onDrop,
        title,
        uploading,
        uploaded,
        imageSrc
    } = props;
    const [isScreenshotDialogOpen, setIsScreenshotDialogOpen] = useState<boolean>(false);
    const preferences = useFetchUserPreferences();
    const handleOpenScreenshotDialog = () => {
        setIsScreenshotDialogOpen(true);
    }

    const handleCloseScreenshotDialog = () => {
        setIsScreenshotDialogOpen(false);
    }

    // We did not add the remaining props to avoid component complexity
    // but you can simply add it if you need to.
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept,
        disabled,
        multiple: false,
        maxFiles: 1,
        onDrop
    });
    const { onClick, ...rest } = getRootProps();
    const { uploader, Component: UploaderPickerDialog, onOpen: onOpenPickUploader } = usePickFormElementUploader();

    const handleClick = (event) => {
        if ([uploader, preferences.fileElementUploader].includes('FILE')) {
            open();
        } else if ([uploader, preferences.fileElementUploader].includes('CAM')) {
            handleOpenScreenshotDialog();
        } else {
            onOpenPickUploader({
                fileCallback: () => onClick(event),
                camCallback: handleOpenScreenshotDialog
            });
        }
    }
    return (<>
        <Card
            {...rest}
            {...(disabled ? {} : { onClick: handleClick })}
            sx={{
                boxShadow: 0,
                borderColor: themeV2.neutral.grey[5],
                borderWidth: 1,
                fontSize: 14,
                borderStyle: 'solid',
                ...(disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }),
                height: 64,
                display: 'flex',
                alignItems: 'center',
                px: 2,
                color: themeV2.colors.blue[100],
                ...(!uploading ? {
                    cursor: 'pointer',
                    '&:hover': {
                        outlineWidth: '2px',
                        outlineStyle: 'solid',
                        borderColor: 'white',
                        outlineColor: (theme) => theme.palette.primary.light,
                    }
                } : {}),
                ...(isDragActive ? {
                    outlineWidth: '3px',
                    outlineStyle: 'solid',
                    outlineColor: (theme) => theme.palette.warning.main,
                } : {})
            }}>
            {(uploaded && !uploading) && <Link
                sx={{
                    borderRadius: .5,
                    overflow: 'hidden',
                    width: 49,
                    mr: 1,
                    height: 29,
                }}
                href={imageSrc}
                onClick={(e) => e.stopPropagation()}>
                <Zoom>
                    <Box
                        component='img'
                        src={imageSrc} alt=''
                        sx={{

                            width: 49,
                            height: 29,
                            objectFit: 'cover',
                        }}
                    />
                </Zoom>
            </Link>}
            <Box sx={{ display: 'flex', flex: 1 }}>
                {title}
            </Box>
            <input {...getInputProps()} />
            {uploading && <CircularProgress variant="indeterminate" sx={{ fontSize: 24 }} />}
            {uploaded && !uploading && <EditPencilIcon />}
            {(!uploading && !uploaded) && <Box sx={{
                backgroundColor: themeV2.colors.blue[10],
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100%'
            }}>
                <Upload />
            </Box>}

        </Card>
        {isScreenshotDialogOpen && <FormElementCamCapture
            title={createUrlSlug(`drivers-license-${title}`)}
            onDrop={files => onDrop(files, [], null)}
            onClose={handleCloseScreenshotDialog} />}
        {UploaderPickerDialog}
    </>)
}