import { format } from 'date-fns';
import { convertToLocalTime } from 'date-fns-timezone';

import { isValidDate } from './is-valid-date';

// format date to server readable format
export const formatDate = (date: Date | string): string => {
    if (typeof date !== 'string' && isValidDate(date)) {
        return format(convertToLocalTime(date, { timeZone: 'US/Arizona' }), "yyy-MM-dd")
    } else {
        return '';
    }
}