import CameraIcon from '@mui/icons-material/Camera';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from 'components/dist/atoms/Button';
import Checkbox from 'components/dist/atoms/Checkbox';
import Dialog from 'components/dist/atoms/Dialog';
import Label from 'components/dist/atoms/Label';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import { useState } from 'react';
import { KeyboardKey } from 'src/constants/ui';
import useEventListener from 'src/hooks/use-event-listener';
import { useFetchUserPreferences } from 'src/hooks/use-fetch-user-prefrences';


export type FORM_ELEMENT_UPLOADER = 'FILE' | 'CAM';

export const usePickFormElementUploader = () => {
    const [uploader, setUploader] = useState<FORM_ELEMENT_UPLOADER>(null);
    const [callbacks, setCallbacks] = useState({});
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const preferences = useFetchUserPreferences();
    const handleSelectUploader = (newUploader: FORM_ELEMENT_UPLOADER, rememberChoice: boolean) => {
        setIsOpen(false);
        if (rememberChoice) {
            setUploader(newUploader);
            preferences.onUpdate({ fileElementUploader: newUploader })
        } else {
            setUploader(null);
        }
        if (callbacks[newUploader]) {
            callbacks[newUploader]();
        }
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const handleOpenDialog = ({ fileCallback, camCallback }: { fileCallback: () => void; camCallback?: () => void }) => {
        setIsOpen(true);
        setCallbacks({ FILE: fileCallback, CAM: camCallback });
    };

    return {
        uploader,
        onOpen: handleOpenDialog,
        Component: <PickUploaderDialog
            onCancel={handleCancel}
            onSelect={handleSelectUploader}
            isOpen={isOpen} />
    }
}

type Props = {
    onSelect: (uploader: FORM_ELEMENT_UPLOADER, rememberChoice: boolean) => void;
    onCancel: () => void;
    isOpen?: boolean;
}

const PickUploaderDialog = ({ onSelect, onCancel, isOpen }: Props) => {
    const [rememberChoice, setRememberChoice] = useState<boolean>(false);

    const handleChange = (checked: boolean) => {
        setRememberChoice(checked);
    }

    const handleSelectCam = () => {
        onSelect('CAM', rememberChoice);
    }

    const handleSelectFile = () => {
        onSelect('FILE', rememberChoice);
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === KeyboardKey.ENTER && isOpen) {
            handleSelectFile();
            onCancel?.();
        }
    }

    useEventListener('keydown', handleKeyDown);

    return (<Dialog
        open={isOpen}
        onOpenChange={(open) => !open && onCancel?.()}
    >
        <Dialog.Content className='p-4 py-10'>
            <Stack space="xl" row items="center" justify='center'>
                <Button
                    variant="outline"
                    onClick={handleSelectCam}>
                    <CameraIcon /> Camera capture
                </Button>
                <Button
                    onClick={handleSelectFile}>
                    <UploadFileIcon />  Upload from device
                </Button>
            </Stack>
            <Separator />
            <Stack space="sm" row items="center">
                <Checkbox name="rememberChoice" id="rememberChoice" onCheckedChange={handleChange} />
                <Label htmlFor='rememberChoice'>
                    Remember this choice, you can change it later in the settings.
                </Label>
            </Stack>
        </Dialog.Content>
    </Dialog>
    );
}