import { DriverLicenseAbbrDesMap } from "src/constants/person";

export function convertDob(text: string) {
    if (typeof text === 'undefined') {
        return ''
    }
    if (text.length !== 8) {
        console.error("convertDob >> string length error: " + text.length + ".  " + text);
        // return (text);
    }

    const month = text.slice(0, 2);
    const day = text.slice(2, 4);
    const year = text.slice(4, 8);

    return `${year}-${month}-${day}`;
}

export var parseDriverLicense = (txt: string) => {
    let lines = txt.split('\n');
    let abbreviations = Object.keys(DriverLicenseAbbrDesMap);
    let map = {};
    lines.forEach((line, i) => {
        let abbreviation: string;
        let content: string;
        if (i === 1) {
            abbreviation = 'DAQ';
            content = line.substring(line.indexOf(abbreviation) + 3);
        } else {
            abbreviation = line.substring(0, 3);
            content = line.substring(3).trim();
        }
        if (abbreviations.includes(abbreviation)) {
            map[abbreviation] = {
                description: DriverLicenseAbbrDesMap[abbreviation],
                content: content
            };
        }
    });
    return map;
};

// function to get extension from filename
export function getExtension(filename: string) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}